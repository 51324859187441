import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { differenceInMinutes } from 'date-fns';

const Authentication = () => {
  const navigate = useNavigate();
  useEffect(() => {
    //clean cache in case of error ()
    const _user = localStorage.getItem('user');
    const _sinceLastLogin = _user ? differenceInMinutes(new Date(), new Date(JSON.parse(_user).timestamp)) : null;
    const _dataKey = localStorage.getItem('dataKey');

    if (!_user || !_dataKey || _sinceLastLogin > 60) {
      // console.log('Forced log-out. Clear all cache');
      console.log('Au-01: OK');
      localStorage.clear();
      navigate('/login');
    }
  }, []);
};

export default Authentication;
