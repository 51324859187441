import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import formatISO from 'date-fns/formatISO';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { CategoryScale } from 'chart.js/auto';

import Navigation from '../Navigation/Navigation';
import LineChart from '../Chart/LineChart';

//register Chart from Chart.js
Chart.register(CategoryScale);

const Forecast = (props) => {
  console.log('Forecast: loaded');
  //fetch real data & draw table
  useEffect(() => {
    async function getDataPreSet() {
      const url = process.env.REACT_APP_API_DATAPRESET;
      fetch(url)
        .then((response) => response.json())
        .then((dt) => {
          // localStorage.setItem('dataKey', JSON.stringify([...dt]));
          localStorage.setItem('dataKey', JSON.stringify(Object.assign({}, dt[0], dt[1], dt[2], dt[3], dt[4], dt[5])));
        })
        .catch((err) => {
          console.log('Fetch dataPreSet: ' + err);
        });
    }
    const dataInStorage = localStorage.getItem('dataKey');
    if (!dataInStorage) {
      getDataPreSet();
    }
  }, []);

  const dataPreSet = JSON.parse(localStorage.getItem('dataKey'));

  function TableHeading({ scenarioIndex }) {
    const scNumber = Number(scenarioIndex) + 1;
    const text = scNumber < 6 ? '24 GIỜ TỚI - KỊCH BẢN: SC0' + scNumber : '01 GIỜ TỚI - KỊCH BẢN DUY NHẤT';
    return <h2 className='heading2'>KẾT QUẢ DỰ BÁO {text}</h2>;
  }
  function SliderButton({ buttonIndex, onButtonClick }) {
    const sc = ['SC01', 'SC02', 'SC03', 'SC04', 'SC05', '01 GIỜ TỚI'];

    return (
      <div className='slider_panel'>
        {sc.map((item, index) => (
          <button
            className={'sliderButton' + (String(buttonIndex) === String(index) ? ' on' : ' off')}
            onClick={(e) => onButtonClick(e.target.id)}
            key={index}
            id={index}>
            {item}
          </button>
        ))}
      </div>
    );
  }
  function DisplayTable({ scData }) {
    return (
      <table className='fcast_24'>
        <thead>
          <tr>
            <th>STT</th>
            <th>Thời điểm</th>
            <th>Load (kW)</th>
            <th>PV (kW)</th>
            <th>Wind (kW)</th>
          </tr>
        </thead>
        <tbody>
          {scData.map((item, index) => (
            <tr key={`r- + ${index}`} className='trow'>
              <td>{index + 1}</td>
              <td>{format(new Date(item.time), 'dd/MM/yyyy hh:mm aa')}</td>
              <td>{parseFloat(item.RMU2_P + item.RMU3_P).toFixed(2)}</td>
              <td>{parseFloat(item.RMU8_P).toFixed(2)}</td>
              <td>{parseFloat(item.RMU4_P).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
  function RightPanelForecastTable() {
    const [sc, setSC] = useState(0);
    let scStr = 'sc0' + (parseInt(sc) + 1);
    const forecastData = dataPreSet.UncerData;
    const forecastData_1hour = dataPreSet.UncerData_1hour;
    let data = sc < 5 ? [...forecastData].filter((x) => scStr === x.sc) : [...forecastData_1hour];

    return (
      <div className='panel_container vtop'>
        <TableHeading scenarioIndex={sc} />
        <SliderButton buttonIndex={sc} onButtonClick={setSC} />
        <DisplayTable scData={data} />
      </div>
    );
  }

  function ChartSlider({ buttonIndex, onButtonClick }) {
    const label = ['24', '01', '00'];
    // index = [0,1,2] 0 = 24h, 1 = 01h, 3 = GHI/WSpeed
    // console.log('Button id:' + buttonIndex);
    return (
      <div className='slider_panel'>
        {label.map((item, index) => (
          <button
            className={'sliderButton ' + (String(buttonIndex) === String(index) ? 'on' : 'off')}
            onClick={(e) => onButtonClick(parseInt(e.target.id))}
            key={index}
            id={index}>
            {index < 2 ? 'TRONG ' + item + ' GIỜ TỚI' : 'BỨC XẠ GHI & TỐC ĐỘ GIÓ'}
          </button>
        ))}
      </div>
    );
  }
  function PlotCharts({ duration }) {
    // index = [0,1,2] 0 = 24h, 1 = 01h, 3 = GHI/WSpeed
    let recentData = duration < 1 ? dataPreSet.recent_1d : dataPreSet.recent_1h;
    let fcData = duration < 1 ? dataPreSet.UncerData : dataPreSet.UncerData_1hour;

    const recentTimeStamp = recentData.map((x) => formatISO(new Date(x.time)));
    // console.log(recentTimeStamp);
    const fcTimeStamp =
      duration < 1
        ? fcData.filter((x) => x.sc === 'sc01').map((x) => formatISO(new Date(x.time)))
        : fcData.map((x) => formatISO(new Date(x.time)));
    // join 2 timestamp to plot a continuous line-chart
    const timestamp = recentTimeStamp.concat(fcTimeStamp);

    let re_data = [dataPreSet.UncerData_1hour, dataPreSet.UncerData];
    let re_time_1 = dataPreSet.UncerData_1hour.map((x) => formatISO(new Date(x.time)));
    let re_time_24 = dataPreSet.UncerData.filter((x) => x.sc === 'sc01').map((x) => formatISO(new Date(x.time)));

    const getFcData = (sc, type) => {
      let scStr = sc > 0 ? 'sc0' + sc : undefined; //vd: sc=1->scStr='sc01'
      //const typeStrArr = ['RMU8_P','RMU4_P','Load','GHI','WT_Wind_Speed'];
      let typeStr = type;
      return fcData
        .filter((x) => x.sc === scStr)
        .map((x) => (typeStr === 'Load' ? x['RMU2_P'] + x['RMU3_P'] : x[typeStr]))
        .map((x) => x.toFixed(2));
    };
    const getReData = (sc, type) => {
      let scStr = sc > 0 ? 'sc0' + sc : undefined; //vd: sc=1->scStr='sc01'
      //const typeStrArr = ['RMU8_P','RMU4_P','Load','GHI','WT_Wind_Speed'];
      let typeStr = type;
      let dataSrc = sc > 0 ? re_data[1] : re_data[0];
      return dataSrc
        .filter((x) => x.sc === scStr)
        .map((x) => x[typeStr])
        .map((x) => x.toFixed(2));
    };
    const convertToObj = (a, b) => {
      if (a.length !== b.length || a.length === 0 || b.length === 0) {
        return null;
      }
      let obj = [];

      // Using the foreach method
      for (let i = 0; a.length > i; i++) {
        obj.push({
          x: a[i],
          y: b[i],
        });
      }
      return obj;
    };

    let loadSC = [],
      windSC = [],
      pvSC = [],
      ghi_24 = [],
      ghi_1 = [],
      windSpd_24 = [],
      windSpd_1 = [];
    if (duration === 0) {
      for (let i = 1; i < 6; i++) {
        loadSC.push(convertToObj(fcTimeStamp, getFcData(i, 'Load')));
        windSC.push(convertToObj(fcTimeStamp, getFcData(i, 'RMU4_P')));
        pvSC.push(convertToObj(fcTimeStamp, getFcData(i, 'RMU8_P')));
      }
    } else if (duration === 1) {
      loadSC.push(convertToObj(fcTimeStamp, getFcData(0, 'Load')));
      windSC.push(convertToObj(fcTimeStamp, getFcData(0, 'RMU4_P')));
      pvSC.push(convertToObj(fcTimeStamp, getFcData(0, 'RMU8_P')));
    } else {
      for (let i = 1; i < 6; i++) {
        ghi_24.push(convertToObj(re_time_24, getReData(i, 'GHI')));
        windSpd_24.push(convertToObj(re_time_24, getReData(i, 'WT_Wind_Speed')));
      }
      ghi_1.push(convertToObj(re_time_1, getReData(0, 'GHI')));
      windSpd_1.push(convertToObj(re_time_1, getReData(0, 'WT_Wind_Speed')));
      console.log(ghi_24);
      console.log(ghi_1);
    }
    // console.log(loadSC);
    // console.log(windSC);
    // console.log(pvSC);
    const recentLoad = convertToObj(
      recentTimeStamp,
      recentData.map((x) => x.RMU2_P + x.RMU3_P)
    );
    const loadBridge = !loadSC[0] ? [] : [recentLoad.at(-1), loadSC[0].at(0)];
    // recentLoad.push(loadSC[0].at(0));

    const recentWind = convertToObj(
      recentTimeStamp,
      recentData.map((x) => x.RMU4_P)
    );
    const windBridge = !windSC[0] ? [] : [recentWind.at(-1), windSC[0].at(0)];
    // recentWind.push(windSC[0].at(0));

    const recentPV = convertToObj(
      recentTimeStamp,
      recentData.map((x) => x.RMU8_P)
    );
    const pvBridge = !pvSC[0] ? [] : [recentPV.at(-1), pvSC[0].at(0)];
    // recentPV.push(pvSC[0].at(0));
    const chartGHI_24 = {
      labels: re_time_24,
      datasets: [
        {
          label: 'Bức xạ Dự báo - SC04',
          data: ghi_24[3],
          borderColor: 'rgb(54, 162, 235)', //blue
          backgroundColor: 'rgba(54, 162, 235, 0.3)', //blue
          borderDash: [3, 3],
          fill: '+1',
        },
        {
          label: 'Bức xạ Dự báo - SC02',
          data: ghi_24[1],
          borderColor: 'rgb(146, 195, 51)', //green
          backgroundColor: 'rgba(46, 195, 51, 0.4)', //green
          fill: '+1',
        },
        {
          label: 'Bức xạ Dự báo - SC01',
          data: ghi_24[0],
          borderColor: 'rgb(255, 86, 29)', //tangerine
          backgroundColor: 'rgba(255, 86, 29,0.5)', //tangerine
          borderDash: [],
        },
        {
          label: 'Bức xạ Dự báo - SC03',
          data: ghi_24[2],
          borderColor: 'rgb(146, 195, 51)', //green
          backgroundColor: 'rgba(46, 195, 51, 0.4)', //green
          fill: '-1',
        },
        {
          label: 'Bức xạ Dự báo - SC05',
          data: ghi_24[4],
          borderColor: 'rgb(54, 162, 235)', //blue
          backgroundColor: 'rgba(54, 162, 235, 0.3)', //blue
          borderDash: [3, 3],
          fill: '-1',
        },
      ],
    };
    const chartGHI_1 = {
      labels: re_time_1,
      datasets: [
        {
          label: 'Bức xạ Dự báo - SC01',
          data: ghi_1[0],
          borderColor: 'rgb(255, 86, 29)', //tangerine
          backgroundColor: 'rgba(255, 86, 29,0.5)', //tangerine
          borderDash: [],
        },
      ],
    };
    const chartWSpd_24 = {
      labels: re_time_24,
      datasets: [
        {
          label: 'Tốc độ gió dự báo - SC04',
          data: windSpd_24[3],
          borderColor: 'rgb(54, 162, 235)', //blue
          backgroundColor: 'rgba(54, 162, 235, 0.3)', //blue
          borderDash: [3, 3],
          fill: '+1',
        },
        {
          label: 'Tốc độ gió dự báo - SC02',
          data: windSpd_24[1],
          borderColor: 'rgb(146, 195, 51)', //green
          backgroundColor: 'rgba(46, 195, 51, 0.4)', //green
          fill: '+1',
        },
        {
          label: 'Tốc độ gió dự báo - SC01',
          data: windSpd_24[0],
          borderColor: 'rgb(255, 86, 29)', //tangerine
          backgroundColor: 'rgba(255, 86, 29,0.5)', //tangerine
          borderDash: [],
        },
        {
          label: 'Tốc độ gió dự báo - SC03',
          data: windSpd_24[2],
          borderColor: 'rgb(146, 195, 51)', //green
          backgroundColor: 'rgba(46, 195, 51, 0.4)', //green
          fill: '-1',
        },
        {
          label: 'Tốc độ gió dự báo - SC05',
          data: windSpd_24[4],
          borderColor: 'rgb(54, 162, 235)', //blue
          backgroundColor: 'rgba(54, 162, 235, 0.3)', //blue
          borderDash: [3, 3],
          fill: '-1',
        },
      ],
    };
    const chartWSpd_1 = {
      labels: re_time_1,
      datasets: [
        {
          label: 'Tốc độ gió dự báo',
          data: windSpd_1[0],
          borderColor: 'rgb(255, 86, 29)', //tangerine
          backgroundColor: 'rgba(255, 86, 29,0.5)', //tangerine
          borderDash: [],
        },
      ],
    };
    const chartData = {
      // labels: [...Array(pvSC01.length + 1).keys()].slice(1),
      labels: timestamp,
      datasets: [
        {
          label: 'CS Dự báo SC04',
          data: loadSC[3],
          borderColor: 'rgb(54, 162, 235)', //blue
          backgroundColor: 'rgba(54, 162, 235, 0.3)', //blue
          borderDash: [3, 3],
          fill: '+1',
        },
        {
          label: 'CS Dự báo SC02',
          data: loadSC[1],
          borderColor: 'rgb(146, 195, 51)', //green
          backgroundColor: 'rgba(46, 195, 51, 0.4)', //green
          fill: '+1',
        },
        {
          label: 'CS Dự báo SC01',
          data: loadSC[0],
          borderColor: 'rgb(255, 86, 29)', //tangerine
          backgroundColor: 'rgba(255, 86, 29,0.5)', //tangerine
          borderDash: [],
        },
        {
          label: 'CS Dự báo SC03',
          data: loadSC[2],
          borderColor: 'rgb(146, 195, 51)', //green
          backgroundColor: 'rgba(46, 195, 51, 0.4)', //green
          fill: '-1',
        },
        {
          label: 'CS Dự báo SC05',
          data: loadSC[4],
          borderColor: 'rgb(54, 162, 235)', //blue
          backgroundColor: 'rgba(54, 162, 235, 0.3)', //blue
          borderDash: [3, 3],
          fill: '-1',
        },
        {
          label: 'CS gần đây',
          data: recentLoad,
          borderColor: 'rgb(201, 203, 207)', //gray
          backgroundColor: 'rgba(201, 203, 207,0.5)', //gray
          borderDash: [],
        },
        {
          label: 'GD-DB',
          data: loadBridge,
          borderColor: 'rgb(201, 203, 207)', //gray
          backgroundColor: 'rgba(201, 203, 207,0.5)', //gray
          borderDash: [1, 2],
        },
      ],
    };
    const chartDataWT = {
      labels: timestamp,
      datasets: [
        {
          label: 'CS Dự báo SC04',
          data: windSC[3],
          borderColor: 'rgb(54, 162, 235)', //blue
          backgroundColor: 'rgba(54, 162, 235, 0.3)', //blue
          borderDash: [3, 3],
          fill: '+1',
        },
        {
          label: 'CS Dự báo SC02',
          data: windSC[1],
          borderColor: 'rgb(146, 195, 51)', //green
          backgroundColor: 'rgba(46, 195, 51, 0.4)', //green
          fill: '+1',
        },
        {
          label: 'CS Dự báo SC01',
          data: windSC[0],
          borderColor: 'rgb(255, 86, 29)', //tangerine
          backgroundColor: 'rgba(255, 86, 29,0.5)', //tangerine
          borderDash: [],
        },
        {
          label: 'CS Dự báo SC03',
          data: windSC[2],
          borderColor: 'rgb(146, 195, 51)', //green
          backgroundColor: 'rgba(46, 195, 51, 0.4)', //green
          fill: '-1',
        },
        {
          label: 'CS Dự báo SC05',
          data: windSC[4],
          borderColor: 'rgb(54, 162, 235)', //blue
          backgroundColor: 'rgba(54, 162, 235, 0.3)', //blue
          borderDash: [3, 3],
          fill: '-1',
        },
        {
          label: 'CS gần đây',
          data: recentWind,
          borderColor: 'rgb(201, 203, 207)', //gray
          backgroundColor: 'rgba(201, 203, 207,0.5)', //gray
          borderDash: [],
        },
        {
          label: 'GD-DB',
          data: windBridge,
          borderColor: 'rgb(201, 203, 207)', //gray
          backgroundColor: 'rgba(201, 203, 207,0.5)', //gray
          borderDash: [1, 2],
        },
      ],
    };
    const chartDataPV = {
      labels: timestamp,
      datasets: [
        {
          label: 'CS Dự báo SC04',
          data: pvSC[3],
          borderColor: 'rgb(54, 162, 235)', //blue
          backgroundColor: 'rgba(54, 162, 235, 0.3)', //blue
          borderDash: [3, 3],
          fill: '+1',
        },
        {
          label: 'CS Dự báo SC02',
          data: pvSC[1],
          borderColor: 'rgb(146, 195, 51)', //green
          backgroundColor: 'rgba(46, 195, 51, 0.4)', //green
          fill: '+1',
        },
        {
          label: 'CS Dự báo SC01',
          data: pvSC[0],
          borderColor: 'rgb(255, 86, 29)', //tangerine
          backgroundColor: 'rgba(255, 86, 29,0.5)', //tangerine
          borderDash: [],
        },
        {
          label: 'CS Dự báo SC03',
          data: pvSC[2],
          borderColor: 'rgb(146, 195, 51)', //green
          backgroundColor: 'rgba(46, 195, 51, 0.4)', //green
          fill: '-1',
        },
        {
          label: 'CS Dự báo SC05',
          data: pvSC[4],
          borderColor: 'rgb(54, 162, 235)', //blue
          backgroundColor: 'rgba(54, 162, 235, 0.3)', //blue
          borderDash: [3, 3],
          fill: '-1',
        },
        {
          label: 'CS ĐMT gần đây',
          data: recentPV,
          borderColor: 'rgb(201, 203, 207)', //gray
          backgroundColor: 'rgba(201, 203, 207,0.5)', //gray
          borderDash: [],
        },
        {
          label: 'GD-DB',
          data: pvBridge,
          borderColor: 'rgb(201, 203, 207)', //gray
          backgroundColor: 'rgba(201, 203, 207,0.5)', //gray
          borderDash: [1, 2],
        },
      ],
    };
    const titleDura = duration < 1 ? '48 giờ trước và dự báo 24 giờ tới (kW)' : '03 giờ trước và dự báo 01 giờ tới (kW)';

    const Chart = ({ type }) => {
      if (type < 2) {
        return (
          <div className='chart_panel forecast_chart'>
            <div className='chart_item_90'>
              <LineChart chartData={chartData} aspectRatio={3.3} legend={true} title={'Công suất Phụ Tải ' + titleDura} />
              <p className='chart_title'>DỰ BÁO CÔNG SUẤT PHỤ TẢI</p>
            </div>
            <div className='chart_item_90'>
              <LineChart chartData={chartDataWT} aspectRatio={3.3} legend={true} title={'Công suất Điện Gió ' + titleDura} />
              <p className='chart_title'>DỰ BÁO CÔNG SUẤT ĐIỆN GIÓ (WIND)</p>
            </div>
            <div className='chart_item_90'>
              <LineChart chartData={chartDataPV} aspectRatio={3.3} legend={true} title={'Công suất Điện Mặt Trời ' + titleDura} />
              <p className='chart_title'>DỰ BÁO CÔNG SUẤT ĐIỆN MẶT TRỜI (PV)</p>
            </div>
          </div>
        );
      } else {
        return (
          <div className='chart_panel forecast_chart_4'>
            <div className='chart_item_45'>
              <LineChart chartData={chartGHI_24} aspectRatio={2} legend={false} title={'Bức xạ dự báo trong 24 giờ tới'} />
              <p className='chart_title'>DỰ BÁO BỨC XẠ</p>
            </div>
            <div className='chart_item_45'>
              <LineChart chartData={chartWSpd_24} aspectRatio={2} legend={false} title={'Tốc độ gió dự báo trong 24 giờ tới'} />
              <p className='chart_title'>DỰ BÁO TỐC ĐỘ GIÓ</p>
            </div>
            <div className='chart_item_45'>
              <LineChart chartData={chartGHI_1} aspectRatio={2} legend={false} title={'Bức xạ dự báo trong 1 giờ tới'} />
              <p className='chart_title'>DỰ BÁO BỨC XẠ</p>
            </div>
            <div className='chart_item_45'>
              <LineChart chartData={chartWSpd_1} aspectRatio={2} legend={false} title={'Tốc độ gió dự báo trong 01 giờ tới'} />
              <p className='chart_title'>DỰ BÁO TỐC ĐỘ GIÓ</p>
            </div>
          </div>
        );
      }
    };

    return (
      // plot charts
      <Chart type={duration} />
    );
  }
  function LeftPanelCharts() {
    const [duration, setDuration] = useState(0);
    return (
      <div className='panel_container vtop'>
        <h2 className='heading2'>ĐỒ THỊ DỰ BÁO CÔNG SUẤT CÁC TỦ RMU</h2>
        <ChartSlider buttonIndex={duration} onButtonClick={setDuration} />
        <PlotCharts duration={duration} />
      </div>
    );
  }

  return (
    <>
      <Navigation loggedIn={props.loggedIn} setLoggedIn={props.setLoggedIn} pageId={2} />
      <section className={'home show'}></section>
      <section className='main_container'>
        <div className='left_panel'>
          <LeftPanelCharts />
        </div>
        <div className='right_panel'>
          <RightPanelForecastTable />
        </div>
      </section>
    </>
  );
};
export default Forecast;
