import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Authentication from './Authentication';

function Navigation(props) {
  const navigate = useNavigate();

  const user = localStorage.user;
  //console.log('Check authentication...');
  console.log('Nav-01: checking');
  Authentication();
  useEffect(() => {
    if (!localStorage.getItem('user') || !localStorage.getItem('dataKey')) {
      localStorage.clear();
      navigate('/login');
    }
  }, []);

  const onButtonClick = () => {
    if (props.loggedIn) {
      localStorage.clear();
      props.setLoggedIn(false);
      navigate('/');
    } else {
      navigate('/login');
    }
  };

  function RenderMenu({ activeId }) {
    const menu = [
      { id: 1, page: '/general', title: 'TỔNG QUAN', active: false },
      { id: 2, page: '/forecast', title: 'DỰ BÁO', active: false },
      { id: 3, page: '/optimise', title: 'TỐI ƯU', active: false },
      { id: 4, page: '/history', title: 'LỊCH SỬ', active: false },
      // { id: 5, page: '/wind', title: 'WIND', active: false },
      // { id: 6, page: '/bess', title: 'BESS', active: false },
      // { id: 7, page: '/diesel', title: 'DIESEL', active: false },
    ];
    const menuItems = [...menu];
    menuItems[menuItems.findIndex((f) => f.id === activeId)].active = true;

    return (
      <ul className='nav_items'>
        {menuItems.map((item) => (
          <li className={'nav_item' + (!item.active ? '' : ' active')} key={item.title} onClick={() => navigate(item.page)}>
            <span className='nav_link'>{item.title}</span>
          </li>
        ))}
      </ul>
    );
  }
  function RenderUserGreeting({ loggedIn }) {
    return (
      <div className='greetingContainer'>
        {loggedIn ? (
          <div className='greeting'>
            Xin chào
            <br />
            <strong>{user && JSON.parse(user).username}</strong>
          </div>
        ) : (
          <div />
        )}
        <span className='button' onClick={onButtonClick}>
          {loggedIn ? <i className='uil uil-signout'></i> : 'ĐĂNG NHẬP'}
        </span>
      </div>
    );
  }
  return (
    <header className='header ontop'>
      <nav className='nav'>
        <span className='nav_logo'>
          <span className='nav_logo green'>RE:</span> BẠCH LONG VĨ
        </span>
        <RenderMenu activeId={props.pageId} />
        <RenderUserGreeting loggedIn={props.loggedIn} />
      </nav>
    </header>
  );
}

export default Navigation;
