// components/LineChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { vi } from 'date-fns/locale';

function LineChartHis({ chartData, aspectRatio, title, legend, lgPosition }) {
  return (
    <div className='chart_container'>
      <Line
        data={chartData}
        options={{
          elements: {
            line: {
              borderWidth: 1,
            },
            point: {
              radius: 1,
            },
          },
          // animation,
          interaction: {
            intersect: false,
          },
          plugins: {
            title: {
              display: true,
              text: title,
            },
            tooltip: {
              filter: function (item) {
                return !item.dataset.label.includes('GD-DB');
              },
              callbacks: {
                label: (item) => `${item.dataset.label}: ${item.formattedValue} kW`,
              },
            },
            legend: {
              display: legend,
              position: lgPosition || 'right',
              labels: {
                boxWidth: 12,
                usePointStyle: true,
                pointStyle: 'line',
                filter: function (item) {
                  // Logic to remove a particular legend item goes here
                  return !item.text.includes('GD-DB');
                },
              },
            },
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'minute',
                displayFormats: {
                  minute: 'dd/MM HH:mm',
                },
              },
              parsing: false,
              adapters: {
                date: {
                  locale: vi,
                },
              },
              ticks: {
                beginAtZero: false,
                stepSize: 60,
              },
            },
            y: {
              title: {
                display: true,
                text: 'kW',
                font: {
                  weight: 'bold',
                },
              },
            },
          },
          // aspectRatio: aspectRatio || 1.5,
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 8,
              right: 0,
            },
          },
          animation: {
            duration: 0,
          },
        }}
      />
    </div>
  );
}
export default LineChartHis;
