import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../Home/Home';
import Login from '../Login/Login';
import General from '../Dashboard/General';
import Optimise from '../Dashboard/Optimise';
import './App.css';
import Forecast from '../Dashboard/Forecast';
import History from '../Dashboard/History';
// import Solar from '../Dashboard/Solar';
// import Wind from '../Dashboard/Wind';
// import Bess from '../Dashboard/Bess';
// import Diesel from '../Dashboard/Diesel';

// import useToken from './useToken';
// function SetTitle() {
//   useEffect(() => {
//     document.title = 'BLV HMI';
//   }, []);
// }

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  // const [dataKey, setDataKey] = useState({});

  useEffect(() => {
    //Fetch the user email and token from local storage
    const user = JSON.parse(localStorage.getItem('user'));
    // console.log(user);
    //If the token/email does not exist, mark the user as logged out
    if (!user || !user.token) {
      setLoggedIn(false);
      return;
    } else {
      //verify user if needed
      setLoggedIn(true);
      setEmail(user.username);
    }

    //If the token exists, verify it with the auth server to see if it is valid
    // fetch('http://localhost:3080/verify', {
    //   method: 'POST',
    //   headers: {
    //     'jwt-token': user.token,
    //   },
    // })
    //   .then((r) => r.json())
    //   .then((r) => {
    //     setLoggedIn('success' === r.message);
    //     setEmail(user.email || '');
    //   });
  }, []);

  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route key='home' path='/' element={loggedIn ? <General setLoggedIn={setLoggedIn} loggedIn={loggedIn} /> : <Home />} />
          <Route key='gen' path='/general' element={loggedIn ? <General setLoggedIn={setLoggedIn} loggedIn={loggedIn} /> : <Home />} />
          <Route key='opti' path='/optimise' element={loggedIn ? <Optimise setLoggedIn={setLoggedIn} loggedIn={loggedIn} /> : <Home />} />
          <Route key='fc' path='/forecast' element={loggedIn ? <Forecast setLoggedIn={setLoggedIn} loggedIn={loggedIn} /> : <Home />} />
          <Route key='history' path='/history' element={loggedIn ? <History setLoggedIn={setLoggedIn} loggedIn={loggedIn} /> : <Home />} />
          {/* <Route key='solar' path='/solar' element={loggedIn ? <Solar setLoggedIn={setLoggedIn} loggedIn={loggedIn} /> : <Home />} />
          <Route key='wind' path='/wind' element={loggedIn ? <Wind setLoggedIn={setLoggedIn} loggedIn={loggedIn} /> : <Home />} />
          <Route key='bess' path='/bess' element={loggedIn ? <Bess setLoggedIn={setLoggedIn} loggedIn={loggedIn} /> : <Home />} />
          <Route key='diesel' path='/diesel' element={loggedIn ? <Diesel setLoggedIn={setLoggedIn} loggedIn={loggedIn} /> : <Home />} /> */}
          <Route key='login' path='/login' element={<Login setLoggedIn={setLoggedIn} setEmail={setEmail} loggedIn={loggedIn} />} />
          <Route key='unknown' path='/*' element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
