import React, { useEffect, useState, useRef } from 'react';
import { format } from 'date-fns';
import Navigation from '../Navigation/Navigation';

const Optimise = (props) => {
  const _ref_modal = useRef(null);
  const _ref_info = useRef(null);
  const _opti_enabled = useRef(false);
  const _incomplete_input = useRef('');

  const _ref_elapsedTime = useRef(null);
  const startTimeRef = useRef(null);
  const intervalIdRef = useRef(null);
  const isLoadingRef = useRef(false);

  const _ref_loading_icon = useRef(null);
  const _ref_bell_icon = useRef(null);

  const startTimer = () => {
    startTimeRef.current = new Date().getTime();
    isLoadingRef.current = true;
    _ref_elapsedTime.current.innerText = '';

    // Start the interval to update elapsed time every second
    intervalIdRef.current = setInterval(() => {
      if (startTimeRef.current && isLoadingRef.current === true) {
        const endTime = new Date().getTime();
        const elapsedSeconds = (endTime - startTimeRef.current) / 1000;
        _ref_elapsedTime.current.innerText = elapsedSeconds.toFixed(2) + 's';
      }
    }, 10);
  };

  const showLoadingInfo = (mes, typeStr) => {
    if (typeStr === 'info') {
      // console.log(typeStr);
      _ref_loading_icon.current.style.display = 'none';
      _ref_elapsedTime.current.style.display = 'none';
      _ref_bell_icon.current.style.display = 'block';
    } else {
      // console.log(typeStr);
      _ref_loading_icon.current.style.display = 'block';
      _ref_elapsedTime.current.style.display = 'block';
      _ref_bell_icon.current.style.display = 'none';
    }

    return (_ref_info.current.innerText = mes);
  };
  function toggleLoading() {
    let el = _ref_modal.current;
    el.classList.toggle('show');
  }
  function isNotNumber(i) {
    //matching a number as float
    if (!i.match(/^([0-9]*[.]?)[0-9]*$/)) return true;
  }

  const [newOptiFlag, setNewOptiFlag] = useState(0);
  console.log('Optimise: loaded');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': JSON.parse(localStorage.getItem('user')).token,
    },
  };
  //fetch real data & draw table
  useEffect(() => {
    async function getDataPreSet() {
      const url = process.env.REACT_APP_API_DATAPRESET;
      fetch(url)
        .then((response) => response.json())
        .then((dt) => {
          // localStorage.setItem('dataKey', JSON.stringify([...dt]));
          localStorage.setItem('dataKey', JSON.stringify(Object.assign({}, dt[0], dt[1], dt[2], dt[3], dt[4], dt[5])));
        })
        .catch((err) => {
          console.log('Fetch dataPreSet: ' + err);
        });
    }
    const dataInStorage = localStorage.getItem('dataKey');
    if (!dataInStorage) {
      getDataPreSet();
    }
  }, [newOptiFlag]);
  const dataPreSet = JSON.parse(localStorage.getItem('dataKey'));
  const optiData_1day = [...dataPreSet.AllOutput_1day];
  const optiData_1hour = [...dataPreSet.AllOutput_1hour];
  // setOpt_cost_1d(_currentDataKey[3].opt_cost_1d); //assign result of otpi to
  // console.log('Opti 1day: ' + optiData_1day);
  // console.log('Opti 1hour: ' + optiData_1hour);
  function OptiTable({ optiData, duration, onButtonClick }) {
    return (
      <div className='panel_container vtop'>
        <h2 className='heading2'>THÔNG SỐ VẬN HÀNH TỐI ƯU SC01 (ĐƠN VỊ: kW)</h2>
        <ChartSlider buttonIndex={duration} onButtonClick={onButtonClick} />
        <DisplayOptiTable duration={duration} data={optiData} />
      </div>
    );
  }
  function ChartSlider({ buttonIndex, onButtonClick, color }) {
    const label = ['24', '01'];
    return (
      <div className={'slider_panel' + (color === 'blue' ? ' blue' : '')}>
        {label.map((item, index) => (
          <button
            className={'sliderButton' + (String(buttonIndex) === String(index) ? ' on' : ' off') + (color === 'blue' ? ' blue' : '')}
            onClick={(e) => onButtonClick(e.target.id)}
            key={index}
            id={index}>
            TRONG {item} GIỜ TỚI
          </button>
        ))}
      </div>
    );
  }
  function DisplayOptiTable({ duration, data }) {
    const showItem = (item) => {
      return parseFloat(item).toFixed(1);
    };
    const optiData = duration < 1 ? [...data.filter((x) => x.sc === 'sc01')] : [...data];
    return (
      <>
        <table className='opti_24 fcast_24 table_95' id='opti_table_24'>
          <thead>
            <tr>
              <th></th>
              <th>Thời điểm</th>
              <th>ESS EN</th>
              <th>ESS PO</th>
              <th>G.1</th>
              <th>G.2</th>
              <th>G.3</th>
              <th>G.4</th>
              <th>G.5</th>
              <th>PV</th>
              <th>Wind</th>
            </tr>
          </thead>
          <tbody>
            {optiData.map((item, index) => (
              <tr key={`r- + ${index}`} className='trow'>
                <td>{index + 1}</td>
                <td>{format(new Date(item.time), 'dd/MM/yyyy hh:mm aa')}</td>
                <td>{showItem(item['ESS_Energy'])}</td>
                <td className={item['ESS_Power_Output'] > 0 ? 'discharged' : item['ESS_Power_Output'] < 0 ? 'charged' : 'other'}>
                  {showItem(item['ESS_Power_Output'])}
                </td>
                <td>{showItem(item.Gen1)}</td>
                <td>{showItem(item.Gen2)}</td>
                <td>{showItem(item.Gen3)}</td>
                <td>{showItem(item.Gen4)}</td>
                <td>{showItem(item.Gen5)}</td>
                <td>{showItem(item.PV1)}</td>
                <td>{showItem(item.Wind1)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }

  function InputTable() {
    return (
      <>
        <h2 className='heading2 blue'>NHẬP DỮ LIỆU ĐẦU VÀO ĐỂ TIẾN HÀNH TỐI ƯU</h2>
        {/* <ChartSlider buttonIndex={duration} onButtonClick={setDuration} color='blue' /> */}
        <DrawGenTable />
        <DrawESSTable />
      </>
    );
  }
  function DrawGenTable() {
    useEffect(() => {
      // const url_re_state = process.env.REACT_APP_API_RE_STATE;
      const url_re_dfGen = process.env.REACT_APP_API_DFGEN;
      // const url_re_dfESS = process.env.REACT_APP_API_DFESS;
      async function getInitialData() {
        try {
          fetch(url_re_dfGen, options)
            .then((response) => response.json())
            .then((dt) => {
              // console.log(dt);
              setInitialGenData([...dt]);
              //save this value to cache
              localStorage.setItem('Gen_States', JSON.stringify([...dt]));
            });
        } catch (err) {
          console.error('Fetch dataPreSet: ' + err);
        }
      }
      getInitialData();
    }, []);
    const df = {
      No: 0,
      Gen: 'genName',
      ConnectState: 0,
      MaxProd: 500,
      MinProd: 0,
      OperatingCost: 0.25,
      MinUpTime: 1,
      MinDownTime: 1,
      StartUpCost: 0,
      ShutDownCost: 0,
      InitialOnOff: 1,
    };
    const dt = [];
    for (let i = 1; i < 6; i++) {
      dt.push({ ...df, No: 6 - i, Gen: 'GEN ' + i });
    }
    const [initialGenData, setInitialGenData] = useState(dt);

    const onChangeInput = (e, id) => {
      let name = e.target.name;
      let itemFullName = 'GEN' + (6 - Number(id)) + '-' + name;
      // console.log(_incomplete_input.current);
      // console.log(itemFullName);
      if (e.target.type !== 'checkbox' && _incomplete_input.current !== '' && itemFullName !== _incomplete_input.current) {
        alert('❌ Thông số ' + _incomplete_input.current + ' chưa được nhập hoàn chỉnh.\nVui lòng hoàn chỉnh trước khi nhập thông số khác');
        return;
      }
      let value = e.target.type === 'checkbox' ? 1 - e.target.value : e.target.value === '' ? '0' : e.target.value;
      // console.log(value);
      if (e.target.type !== 'checkbox') {
        if (isNotNumber(value) && (value.slice(-1) !== '.' || value.split('').filter((c) => c === '.').length > 1)) {
          alert('❌ Thông số ' + e.target.name + ' không được chứa ký tự lạ và phải là một số nguyên hay số thập phân.');
          return;
        }

        if (value.indexOf('.') === -1) {
          value = Number(value);
          _incomplete_input.current = '';
        } else {
          if (value.endsWith('.') || value.endsWith('0')) {
            _incomplete_input.current = itemFullName;
          } else {
            value = Number(value);
            _incomplete_input.current = '';
          }
        }
      }
      const editData = initialGenData.map((item) => (parseInt(item.No) === parseInt(id) && name ? { ...item, [name]: value } : item));
      // console.log(editData);
      //enable opti process
      _opti_enabled.current = true;
      setInitialGenData(editData);
      sessionStorage.setItem('current_dfGen', JSON.stringify(editData));
    };
    return (
      <table className='gen_opti table_95'>
        <thead>
          {/* prettier-ignore */}
          <tr>
            <th rowSpan={2}></th>
            <th rowSpan={2}>Connect. State</th>
            <th className='no_border'>Max<br />Prod.</th>
            <th className='no_border'>Min<br />Prod.</th>
            <th className='no_border'>Operating<br />Cost</th>
            <th className='no_border'>Min<br />Up-Time</th>
            <th className='no_border'>Min<br />Down-Time</th>
            <th className='no_border'>StartUp<br />Cost</th>
            <th className='no_border'>ShutDown<br />Cost</th>
            <th rowSpan={2}>Initial (On/Off)</th>
          </tr>
          <tr>
            <th>[kW]</th>
            <th>[kW]</th>
            <th>[VND/kW]</th>
            <th>[h]</th>
            <th>[h]</th>
            <th>[VND]</th>
            <th>[VND]</th>
          </tr>
          {/* prettier-ignore-end */}
        </thead>
        <tbody>
          {initialGenData.map((gen) => (
            <tr key={gen.No}>
              <td>{gen.Gen}</td>
              <td className='editable_box'>
                <input
                  name='ConnectState'
                  value={gen.ConnectState}
                  type='checkbox'
                  checked={parseInt(gen.ConnectState) === 1 ? true : false}
                  onChange={(e) => onChangeInput(e, gen.No)}
                />
                <i className='uil uil-edit-alt'></i>
              </td>
              <td className='editable_box'>
                <input name='MaxProd' value={gen.MaxProd} type='text' onChange={(e) => onChangeInput(e, gen.No)} />
                <i className='uil uil-edit-alt'></i>
              </td>
              <td className='editable_box'>
                <input name='MinProd' value={gen.MinProd} type='text' onChange={(e) => onChangeInput(e, gen.No)} />
                <i className='uil uil-edit-alt'></i>
              </td>
              <td className='editable_box'>
                <input name='OperatingCost' value={gen.OperatingCost} type='text' onChange={(e) => onChangeInput(e, gen.No)} />
                <i className='uil uil-edit-alt'></i>
              </td>
              <td className='editable_box'>
                <input name='MinUpTime' value={gen.MinUpTime} type='text' onChange={(e) => onChangeInput(e, gen.No)} />
                <i className='uil uil-edit-alt'></i>
              </td>
              <td className='editable_box'>
                <input name='MinDownTime' value={gen.MinDownTime} type='text' onChange={(e) => onChangeInput(e, gen.No)} />
                <i className='uil uil-edit-alt'></i>
              </td>
              <td className='editable_box'>
                <input name='StartUpCost' value={gen.StartUpCost} type='text' onChange={(e) => onChangeInput(e, gen.No)} />
                <i className='uil uil-edit-alt'></i>
              </td>
              <td className='editable_box'>
                <input name='ShutDownCost' value={gen.ShutDownCost} type='text' onChange={(e) => onChangeInput(e, gen.No)} />
                <i className='uil uil-edit-alt'></i>
              </td>
              <td className='editable_box'>
                <input
                  name='InitialOnOff'
                  value={gen.InitialOnOff}
                  type='checkbox'
                  checked={parseInt(gen.InitialOnOff) === 1 ? true : false}
                  onChange={(e) => onChangeInput(e, gen.No)}
                />
                <i className='uil uil-edit-alt'></i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
  function DrawESSTable() {
    useEffect(() => {
      // const url_re_state = process.env.REACT_APP_API_RE_STATE;
      // const url_re_dfESS = process.env.REACT_APP_API_DFESS;
      const url_re_dfESS = process.env.REACT_APP_API_DFESS;
      async function getInitialData() {
        try {
          fetch(url_re_dfESS, options)
            .then((response) => response.json())
            .then((dt) => {
              // console.log(dt);
              setInitialData([...dt]);
              localStorage.setItem('initialData', JSON.stringify([...dt]));
            });
        } catch (err) {
          console.error('Fetch dataPreSet: ' + err);
        }
      }
      getInitialData();
    }, []);
    const [initialData, setInitialData] = useState([
      {
        'Set(es)': 'es1',
        ConnectState: 1,
        MaximumPower: 500,
        MaximumStorage: 2000,
        MinimumStorage: 100,
        Efficiency: 0.96,
        InitialStorage: 1000,
      },
    ]);
    const [lastHourValue, setLastHourValue] = useState(1000);

    useEffect(() => {
      const url_re_state = process.env.REACT_APP_API_RE_STATE;
      // const url_re_dfESS = process.env.REACT_APP_API_DFESS;
      // const url_re_dfESS = process.env.REACT_APP_API_DFESS;
      async function getInitialData() {
        try {
          fetch(url_re_state, options)
            .then((response) => response.json())
            .then((dt) => {
              // console.log(dt);
              setRe_conn_state([...dt]);
              localStorage.setItem('RE_conn_state', JSON.stringify([...dt]));
            });
        } catch (err) {
          console.error('Fetch dataPreSet: ' + err);
        }
      }
      getInitialData();
    }, []);
    const [RE_conn_state, setRe_conn_state] = useState([
      { No: 1, RE: 'PV1', ConnectState: 0 },
      { No: 2, RE: 'Wind1', ConnectState: 0 },
    ]);

    const opt_cost_1d = JSON.parse(localStorage.dataKey).opt_cost_1d;
    const onChangeInput = (e, id) => {
      let name = e.target.name;
      let itemFullName = id.toUpperCase() + '-' + name;
      // console.log(_incomplete_input.current);
      // console.log(itemFullName);
      if (e.target.type !== 'checkbox' && _incomplete_input.current !== '' && itemFullName !== _incomplete_input.current) {
        alert('❌ Thông số ' + _incomplete_input.current + ' chưa được nhập hoàn chỉnh.\nVui lòng hoàn chỉnh trước khi nhập thông số khác');
        return;
      }
      let value = e.target.type === 'checkbox' ? 1 - e.target.value : e.target.value === '' ? '0' : e.target.value;
      // console.log(value);
      if (e.target.type !== 'checkbox') {
        if (isNotNumber(value) && (value.slice(-1) !== '.' || value.split('').filter((c) => c === '.').length > 1)) {
          alert('❌ Thông số ' + e.target.name + ' không được chứa ký tự lạ và phải là một số nguyên hay số thập phân.');
          return;
        }

        if (value.indexOf('.') === -1) {
          value = Number(value);
          _incomplete_input.current = '';
        } else {
          if (value.endsWith('.') || value.endsWith('0')) {
            _incomplete_input.current = itemFullName;
          } else {
            value = Number(value);
            _incomplete_input.current = '';
          }
        }
      }

      // if (value.slice(-1) !== '.') {
      //   let _curr = _incomplete_input.current;
      //   if (_curr !== '' && name === _curr) {
      //     _incomplete_input.current = '';
      //     console.log('Incomplete input: ' + _incomplete_input.current);
      //   }
      //   value = Number(value);
      // } else {
      //   _incomplete_input.current = name;
      //   console.log('Incomplete input: ' + _incomplete_input.current);
      // }

      const editData = initialData.map((item) => (item['Set(es)'] === id && name ? { ...item, [name]: value } : item));
      //enable opti process
      _opti_enabled.current = true;
      setInitialData(editData);
    };
    const onChangeLHV = (e) => {
      setLastHourValue(e.target.value);
    };
    const onChangeREStateInput = (e, id) => {
      const { name, value } = e.target;
      const editData = RE_conn_state.map((re) => (re.No === id && name ? { ...re, ConnectState: 1 - value } : re));
      //enable opti process
      _opti_enabled.current = true;
      setRe_conn_state(editData);
    };

    const onOptiButtonClicked = async () => {
      if (_opti_enabled.current && _incomplete_input.current === '') {
        const Gen_States = JSON.parse(sessionStorage.getItem('current_dfGen'));
        const saveInputURL = process.env.REACT_APP_API_SAVE_INPUT;
        //save the current input
        localStorage.setItem('lastInputValue', JSON.stringify({ initialData, RE_conn_state, Gen_States }));
        //start the timer
        startTimer();
        try {
          const response = await fetch(saveInputURL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-access-token': JSON.parse(localStorage.getItem('user')).token,
            },
            body: JSON.stringify({ initialData, RE_conn_state, Gen_States }),
          });
          const r = await response.json();

          if ('success' === r.message) {
            toggleLoading();
            showLoadingInfo('1️⃣ Lưu tham số đầu vào thành công ✅\n 2️⃣ Đang tính toán thông số vận hành tối ưu ✅');
            const updateOptiURL = process.env.REACT_APP_API_UPDATE_OPTI;

            const res = await fetch(updateOptiURL, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'x-access-token': JSON.parse(localStorage.getItem('user')).token,
              },
            });
            const parsedRes = await res.json();
            showLoadingInfo('3️⃣ Hoàn thành tính toán thông số vận hành tối ưu ✅\n🎉 Đang hiện thị kết quả ra màn hình ✅');
            _ref_elapsedTime.current.innerHTML = '<span>✔</span>';
            let _currentDataKey = JSON.parse(localStorage.getItem('dataKey'));
            // console.log(_currentDataKey);
            _currentDataKey.AllOutput_1day = parsedRes.AllOutput_1day;
            _currentDataKey.GenUC_1day = parsedRes.GenUC_1day;
            _currentDataKey.opt_cost_1d = parsedRes.opt_cost_1d;
            _currentDataKey.AllOutput_1hour = parsedRes.AllOutput_1hour;
            _currentDataKey.opt_cost_1h = parsedRes.opt_cost_1h;

            localStorage.removeItem('dataKey');
            localStorage.setItem('dataKey', JSON.stringify(_currentDataKey));

            // setIsStart(false);
            setTimeout(toggleLoading, 3000);
            _opti_enabled.current = false; //opti is not enabled until an input is changed
            setNewOptiFlag(newOptiFlag + 1); //re-render the whole optimise page
          } else {
            showLoadingInfo('🚧 🚧 🚧 Hệ thống đang thực hiện các nhiệm vụ khác. 🚧 🚧 🚧\nVui lòng thử lại sau vài phút', 'info');
            setTimeout(toggleLoading, 3000);
          }
        } catch (err) {
          console.log('Đã có lỗi trong quá trình thực hiện tối ưu.');
          showLoadingInfo('🚧 🚧 🚧 Hệ thống không phản hồi. (500) 🚧 🚧 🚧\nVui lòng thử lại sau vài phút', 'info');
          setTimeout(toggleLoading, 3000);
          console.error(err);
        } finally {
          isLoadingRef.current = false;
          clearInterval(intervalIdRef.current);
        }
      } else {
        let msg =
          _incomplete_input.current !== ''
            ? '❎ Có thông số đầu vào chưa phù hợp (' + _incomplete_input.current + ')\n❌ Chưa sẵn sàng để thực hiện tối ưu.'
            : '❌ Chưa có thay đổi nào về các thông số đầu vào\n❎ Huỷ bỏ yêu cầu thực hiện tối ưu.';
        _ref_elapsedTime.current.innerText = '';
        toggleLoading();
        showLoadingInfo(msg, 'info');
        setTimeout(toggleLoading, 3000);
      }
    };

    return (
      <>
        <table className='gen_opti ess_opti table_95'>
          <thead>
            {/* prettier-ignore */}
            <tr>
              <th></th>
              <th>Connect<br/>State</th>
              <th>Max<br />Power</th>
              <th>Max<br />Storage</th>
              <th>Min<br />Storage</th>
              <th>Efficiency</th>
              <th>Initial<br />Storage</th>
              <th>Last Hour<br />{format(new Date(), 'HH:00 aa')}</th>
            </tr>
            {/* prettier-ignore-end */}
          </thead>
          <tbody>
            {initialData.map((ess) => (
              <tr key={ess['Set(es)']}>
                <td>ES1</td>
                <td className='editable_box'>
                  <input
                    name='ConnectState'
                    value={ess.ConnectState}
                    type='checkbox'
                    checked={parseInt(ess.ConnectState) === 1 ? true : false}
                    onChange={(e) => onChangeInput(e, ess['Set(es)'])}
                  />
                  <i className='uil uil-edit-alt'></i>
                </td>
                <td className='editable_box'>
                  <input name='MaximumPower' value={ess.MaximumPower} type='text' onChange={(e) => onChangeInput(e, ess['Set(es)'])} />
                  <i className='uil uil-edit-alt'></i>
                </td>
                <td className='editable_box'>
                  <input name='MaximumStorage' value={ess.MaximumStorage} type='text' onChange={(e) => onChangeInput(e, ess['Set(es)'])} />
                  <i className='uil uil-edit-alt'></i>
                </td>
                <td className='editable_box'>
                  <input name='MinimumStorage' value={ess.MinimumStorage} type='text' onChange={(e) => onChangeInput(e, ess['Set(es)'])} />
                  <i className='uil uil-edit-alt'></i>
                </td>
                <td className='editable_box'>
                  <input name='Efficiency' value={ess.Efficiency} type='text' onChange={(e) => onChangeInput(e, ess['Set(es)'])} />
                  <i className='uil uil-edit-alt'></i>
                </td>
                <td className='editable_box'>
                  <input name='InitialStorage' value={ess.InitialStorage} type='text' onChange={(e) => onChangeInput(e, ess['Set(es)'])} />
                  <i className='uil uil-edit-alt'></i>
                </td>
                <td className='editable_box'>
                  <input name='lastHourValue' value={lastHourValue} type='text' onChange={(e) => onChangeLHV(e)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='ess_opti_container table_95'>
          <table className='gen_opti re_state_opti'>
            <thead>
              <tr>
                <th style={{ width: '50%' }}></th>
                <th style={{ width: '25%' }}>PV1</th>
                <th style={{ width: '25%' }}>Wind1</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>RE Conn. State</td>
                {RE_conn_state.map((item) => (
                  <td className='editable_box' key={item.No}>
                    <input
                      name={item.RE}
                      value={item.ConnectState}
                      type='checkbox'
                      checked={parseInt(item.ConnectState) > 0 ? true : false}
                      onChange={(e) => onChangeREStateInput(e, item.No)}
                    />
                    <i className='uil uil-edit-alt'></i>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          <table className='gen_opti opti_res'>
            <thead>
              <tr>
                <th>Flag State</th>
                <th>Total Cost</th>
                <th style={{ width: '30%' }}>Time</th>
              </tr>
            </thead>
            <tbody>
              {opt_cost_1d.slice(-1).map((item, index) => (
                <tr key={index}>
                  <td>{item.Flag_state}</td>
                  <td>{item.TotalCost.toFixed(2)}</td>
                  <td style={{ fontSize: '80%' }}>{format(new Date(item.time), 'dd/MM/yyyy hh:mm:ss a')}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <button className='opti_button' id='btnOpti' onClick={() => onOptiButtonClicked()}>
            ⚙️ TỐI ƯU
          </button>
        </div>
      </>
    );
  }

  function GenOptiTable({ optiData, duration, onButtonClick }) {
    return (
      <div className='gen_opti_container'>
        <h2 className='heading2' style={{ marginTop: '25px' }}>
          TRẠNG THÁI ON/OFF TỐI ƯU CỦA CÁC MÁY PHÁT
        </h2>
        <ChartSlider buttonIndex={duration} onButtonClick={onButtonClick} />
        <DisplayGenOptiTable duration={duration} genOptiData={optiData} />
      </div>
    );
  }
  function DisplayGenOptiTable({ duration, genOptiData }) {
    const data = duration < 1 ? [...genOptiData.filter((x) => x.sc === 'sc01')] : [...genOptiData];
    const len = data.length;
    const ts = data.map((x) => format(new Date(x.time), len < 24 ? 'hh:mm' : 'hh'));
    const ts2 = data.map((x) => format(new Date(x.time), 'aa'));
    const genData = [];
    for (let i = 1; i < 6; i++) {
      genData.push(['GEN ' + i].concat(data.map((x) => x['Gen' + i])));
    }
    // console.log(genData);
    return (
      <>
        <table className='gen_opti_table table_95' id='gen_opti_table'>
          <thead>
            <tr>
              <th rowSpan={2}>&nbsp;</th>
              {ts.map((t, index) => (
                <th key={index}>{t}</th>
              ))}
            </tr>
            <tr>
              {ts2.map((t, index) => (
                <th key={index}>{t}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {genData.map((gen) => (
              <tr key={gen[0]}>
                <td>{gen[0]}</td>
                {gen.slice(1).map((item, index) => (
                  <td key={index} className={item > 0 ? 'on' : 'off'}>
                    {/* {item > 0 ? '⦿' : '-'} */}
                    {item > 0 ? 'ϟ' : '-'}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }

  function DisplayOptiPage({ optiData_1day, optiData_1hour }) {
    const [duration, setDuration] = useState(0);
    const optiData = duration < 1 ? optiData_1day : optiData_1hour;
    return (
      <section className='main_container'>
        <div className='left_panel'>
          <div className='panel_container vtop'>
            <InputTable />
            <GenOptiTable optiData={optiData} duration={duration} onButtonClick={setDuration} />
          </div>
        </div>
        <div className='right_panel'>
          <OptiTable optiData={optiData} duration={duration} onButtonClick={setDuration} />
        </div>
      </section>
    );
  }
  return (
    <>
      <Navigation loggedIn={props.loggedIn} setLoggedIn={props.setLoggedIn} pageId={3} />
      <section className={'home show'}></section>
      <DisplayOptiPage optiData_1day={optiData_1day} optiData_1hour={optiData_1hour} />
      <section className='modal_box' ref={_ref_modal}>
        <div className='message_box'>
          <div id='loading' ref={_ref_loading_icon}></div>
          <p id='elapsed_time' ref={_ref_elapsedTime}></p>
          <div id='bell' ref={_ref_bell_icon}>
            <span className='bell'>🔔</span>
          </div>
          <p id='loading_info' ref={_ref_info}></p>
        </div>
      </section>
    </>
  );
};
export default Optimise;
