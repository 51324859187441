import React, { useEffect, useState, useRef } from 'react';
import { format } from 'date-fns';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { CategoryScale } from 'chart.js/auto';

import Navigation from '../Navigation/Navigation';
import LineChartHis from '../Chart/LineChartHis';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import './Datetimerange.css';

//register Chart from Chart.js
Chart.register(CategoryScale);

const History = (props) => {
  console.log('History: loaded');

  const _timeRange = JSON.parse(localStorage.getItem('timeRange'));
  const tempValue = useRef([new Date(parseFloat(_timeRange.from)), new Date(parseFloat(_timeRange.to))]);
  const [, forceRender] = useState(false);
  const [finalValue, setFinalValue] = useState([new Date(), new Date()]);
  const [dataHistory, setDataHistory] = useState(JSON.parse(localStorage.getItem('dataHistory')));

  //fetch real data & draw table
  // useEffect(() => {
  //   async function getDataPreSet() {
  //     const _from = new Date(value[0]).getTime(); //convert local time to timestampt in millisec
  //     const _to = new Date(value[1]).getTime(); //convert local time to timestampt in millisec
  //     const url = process.env.REACT_APP_API_HISTORY + '?json={"from":"' + _from + 'ms","to":"' + _to + 'ms"}';
  //     console.log(url);
  //     fetch(url)
  //       .then((response) => response.json())
  //       .then((dt) => {
  //         localStorage.setItem('dataHistory', JSON.stringify(dt));
  //         const timeRange = {
  //           from: _from,
  //           to: _to,
  //         };
  //         localStorage.setItem('timeRange', JSON.stringify(timeRange));
  //       })
  //       .catch((err) => {
  //         console.log('Fetch dataHistory: ' + err);
  //       });
  //   }
  //   const dataHistoryInStorage = localStorage.getItem('dataHistory');
  //   if (!dataHistoryInStorage) {
  //     getDataPreSet();
  //   }
  // }, []);
  // Get data
  const handleDateChange = (newRange) => {
    tempValue.current = newRange;
    forceRender((prev) => !prev); // Toggle the state to force a re-render of DateTimeRangePicker
  };
  useEffect(() => {
    // console.log(dataHistory);
    const _from = new Date(tempValue.current[0]).getTime(); //convert local time to timestampt in millisec
    const _to = new Date(tempValue.current[1]).getTime(); //convert local time to timestampt in millisec
    const url_getData = process.env.REACT_APP_API_HISTORY + '?json={"from":"' + _from + 'ms","to":"' + _to + 'ms"}';
    // console.log(url_getData);

    const fetchData = async () => {
      try {
        fetch(url_getData)
          .then((response) => response.json())
          .then((dt) => {
            setDataHistory(dt);
            localStorage.setItem('dataHistory', JSON.stringify(dt));
            const timeRange = {
              from: _from,
              to: _to,
            };
            localStorage.setItem('timeRange', JSON.stringify(timeRange));
          });
      } catch (err) {
        console.error('Fetch dataPreSet: ' + err);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    // console.log(dataHistory);
    const _from = new Date(tempValue.current[0]).getTime(); //convert local time to timestampt in millisec
    const _to = new Date(tempValue.current[1]).getTime(); //convert local time to timestampt in millisec
    const url_getData = process.env.REACT_APP_API_HISTORY + '?json={"from":"' + _from + 'ms","to":"' + _to + 'ms"}';
    // console.log(url_getData);

    const fetchData = async () => {
      try {
        fetch(url_getData)
          .then((response) => response.json())
          .then((dt) => {
            setDataHistory(dt);
            localStorage.setItem('dataHistory', JSON.stringify(dt));
            const timeRange = {
              from: _from,
              to: _to,
            };
            localStorage.setItem('timeRange', JSON.stringify(timeRange));
          });
      } catch (err) {
        console.error('Fetch dataPreSet: ' + err);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [finalValue]);

  const PlotChart = (id) => {
    const typeId = id.typeId;
    // console.log(typeId);
    const chartTypeArr = ['RMU System (Phụ tải)', 'Diesel Generator 1,2,3', 'Diesel Generator 4,5', 'BESS', 'Solar Panel', 'Wind Turbine'];
    const dataSrcArr = ['RMU2_P+RMU3_P', 'RMU1_P', 'RMU6_P', 'RMU7_P', 'RMU8_P', 'WT_Active_Power_'];
    let dataSrc = dataSrcArr[typeId];
    // console.log(dataSrc);
    let dataValues = dataHistory.history.map((item) => (typeId > 0 ? item[dataSrc] : item.RMU2_P + item.RMU3_P));
    let ts = dataHistory.history.map((item) => item.time);
    // console.log(dataValues);
    const chartData = {
      // labels: [...Array(pvSC01.length + 1).keys()].slice(1),
      labels: ts,
      datasets: [
        {
          label: 'CS của ' + chartTypeArr[typeId],
          data: dataValues,
          borderColor: 'rgb(54, 162, 235)', //blue
        },
      ],
    };

    const _from = format(new Date(ts[0]), 'dd/MM/yyyy');
    const _to = format(new Date(ts[ts.length - 1]), 'dd/MM/yyyy');

    return (
      <div className='chart_item_100'>
        <LineChartHis
          chartData={chartData}
          aspectRatio={3.3}
          legend={true}
          lgPosition={'bottom'}
          title={chartTypeArr[typeId] + ': từ ' + _from + ' đến ' + _to}
        />
      </div>
    );
  };

  function LeftPanelCharts() {
    return (
      <div className='panel_container vtop'>
        <div className='history_container'>
          <h2 className='heading2'>Chọn khoảng thời gian tra cứu lịch sử : </h2>
        </div>
        {dataHistory && (
          <div className='chart_panel history_chart'>
            <PlotChart typeId='3' />
            <PlotChart typeId='4' />
            <PlotChart typeId='5' />
          </div>
        )}
      </div>
    );
  }
  function RightPanelCharts() {
    return (
      <div className='panel_container vtop'>
        <div className='history_container flex_left'>
          <div className='history_inner_container'>
            <DateTimeRangePicker
              value={tempValue.current}
              onChange={handleDateChange}
              maxDetail='hour'
              minDate={new Date('2022-10-01')}
              maxDate={new Date('2024-01-31')}
              // locale={'vi-VI'}
              format='dd MMM y'
              clearIcon={null}
              rangeDivider={' đến   '}
              calendarIcon={
                <svg xmlns='http://www.w3.org/2000/svg' width='18px' height='18px' viewBox='0 0 24 24'>
                  <path
                    fill='#fff'
                    d='M12 19a1 1 0 1 0-1-1a1 1 0 0 0 1 1m5 0a1 1 0 1 0-1-1a1 1 0 0 0 1 1m0-4a1 1 0 1 0-1-1a1 1 0 0 0 1 1m-5 0a1 1 0 1 0-1-1a1 1 0 0 0 1 1m7-12h-1V2a1 1 0 0 0-2 0v1H8V2a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3m1 17a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-9h16Zm0-11H4V6a1 1 0 0 1 1-1h1v1a1 1 0 0 0 2 0V5h8v1a1 1 0 0 0 2 0V5h1a1 1 0 0 1 1 1ZM7 15a1 1 0 1 0-1-1a1 1 0 0 0 1 1m0 4a1 1 0 1 0-1-1a1 1 0 0 0 1 1'></path>
                </svg>
              }
            />
            <button
              className='historyButton'
              onClick={() => {
                setFinalValue(tempValue.current);
              }}>
              Tra cứu
            </button>
          </div>
        </div>
        {dataHistory && (
          <div className='chart_panel history_chart'>
            <PlotChart typeId='0' />
            <PlotChart typeId='1' />
            <PlotChart typeId='2' />
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <Navigation loggedIn={props.loggedIn} setLoggedIn={props.setLoggedIn} pageId={4} />
      <section className={'home show'}></section>
      <section className='main_container'>
        <div className='left_panel'>
          <LeftPanelCharts />
        </div>
        <div className='right_panel'>
          <RightPanelCharts />
        </div>
      </section>
    </>
  );
};
export default History;
