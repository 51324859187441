import { formatISO } from 'date-fns';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = (props) => {
  console.log('Login: loaded');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const closeForm = () => {
    navigate('/');
  };
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const onButtonClick = () => {
    //set initial values to empty
    setEmailError('');
    setpasswordError('');
    //check if the user has entered both fields correctly
    if ('' === email) {
      setEmailError('Vui lòng nhập tên đăng nhập');
      return;
    }
    if ('' === password) {
      setpasswordError('Vui lòng nhập mật khẩu');
      return;
    }
    if (password.length < 8) {
      setpasswordError('Mật khẩu phải từ 8 ký tự trở lên');
      return;
    }

    //check if email has an account associated with it
    // checkAccountExists((accountExists) => {
    //   //If yes, log in
    //   if (accountExists) {
    //     logIn();
    //   } else {
    //     if (
    //       alert(
    //         'Không tìm thấy tài khoản: ' +
    //           email +
    //           '. Vui lòng nhập lại tài khoản'
    //       )
    //     ) {
    //       return;
    //     }
    //   }
    // });
    logIn();
  };

  //Call the server API to check if the given email ID already exists
  // const checkAccountExists = (callback) => {
  //   fetch('http://localhost:3080/check-account', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ email }),
  //   })
  //     .then((r) => r.json())
  //     .then((r) => {
  //       callback(r?.userExists);
  //     });
  // };

  const logIn = () => {
    let username = email;
    authenticateUser({ username });
  };
  const authenticateUser = async ({ username }) => {
    const apiURL = process.env.REACT_APP_API_LOGIN;
    fetch(apiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.accessToken) {
          localStorage.setItem('user', JSON.stringify({ username, token: r.accessToken, timestamp: formatISO(new Date()) }));
          props.setLoggedIn(true);
          props.setEmail(email);
          getDataPreSet();
        } else {
          window.alert('Wrong email or password');
        }
      });
  };
  // const getDataPreSet = async () => {
  //   const url = process.env.REACT_APP_API_DATAPRESET;
  //   try {
  //     fetch(url)
  //       .then((response) => response.json())
  //       .then((dt) => {
  //         localStorage.setItem('dataKey', JSON.stringify({ dt }));
  //         if (localStorage.getItem('dataKey')) navigate('/general');
  //       });
  //   } catch (err) {
  //     console.error('Fetch dataPreSet: ' + err);
  //     return;
  //   }
  // };
  async function getDataPreSet() {
    const _timeRange = JSON.parse(localStorage.getItem('timeRange')) || null;

    if (!_timeRange) {
      const tR = {
        from: new Date('2024-01-30 00:00:00').getTime(),
        to: new Date('2024-01-30 23:59:59').getTime(),
      };
      localStorage.setItem('timeRange', JSON.stringify(tR));
    }
    // const url_predict_1h = process.env.REACT_APP_API_DATAPRESET;
    const url_predict_1h = process.env.REACT_APP_API_PREDICT_1H;
    const url_predict_1d = process.env.REACT_APP_API_PREDICT_1D;
    const url_opti_1h = process.env.REACT_APP_API_OPTI_1H;
    const url_opti_1d = process.env.REACT_APP_API_OPTI_1D;
    const url_recent_1h = process.env.REACT_APP_API_RECENT_1H;
    const url_recent_1d = process.env.REACT_APP_API_RECENT_1D;
    try {
      Promise.all([
        fetch(url_predict_1d),
        fetch(url_predict_1h),
        fetch(url_opti_1d),
        fetch(url_opti_1h),
        fetch(url_recent_1d),
        fetch(url_recent_1h),
      ])
        // .then((response) => response.json())
        .then((responses) => {
          return Promise.all(
            responses.map((response) => {
              return response.json();
            })
          );
        })
        .then((dt) => {
          localStorage.setItem('dataKey', JSON.stringify(Object.assign({}, dt[0], dt[1], dt[2], dt[3], dt[4], dt[5])));
          if (localStorage.getItem('dataKey')) navigate('/general');
        });
    } catch (err) {
      console.error('Fetch dataPreSet: ' + err);
    }
  }

  return (
    <section className={'home show'}>
      <div className='form_container'>
        <i className='uil uil-times form_close' onClick={closeForm}></i>
        <div className='form login_form'>
          <h2 className='heading2'>Đăng nhập</h2>
          <div className='input_box'>
            <input type='text' placeholder='Tên đăng nhập' value={email} required onChange={(e) => setEmail(e.target.value)} />
            <i className='uil uil-envelope-alt email'></i>
            {'' !== emailError && <label className='errorMessage'>{emailError}</label>}
          </div>
          <div className='input_box'>
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              placeholder='Mật khẩu'
              required
              onChange={(ev) => setPassword(ev.target.value)}
            />
            <i className='uil uil-lock password'></i>
            <i className={showPassword ? 'uil pw_hide uil-eye' : 'uil pw_hide uil-eye-slash'} onClick={togglePassword}></i>
            {'' !== passwordError && <label className='errorMessage'>{passwordError}</label>}
          </div>
          <div className='button white center' onClick={onButtonClick}>
            <i className='uil uil-signin'></i> ĐĂNG NHẬP
          </div>
        </div>
      </div>
    </section>
  );
};
export default Login;
