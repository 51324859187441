// components/LineChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { vi } from 'date-fns/locale';

function LineChart({ chartData, aspectRatio, title, legend, lgPosition }) {
  // const totalDuration = 10000;
  // const delayBetweenPoints = totalDuration / 200;
  // const previousY = (ctx) =>
  //   ctx.index === 0
  //     ? ctx.chart.scales.y.getPixelForValue(100)
  //     : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y;
  // const animation = {
  //   x: {
  //     type: 'number',
  //     easing: 'linear',
  //     duration: delayBetweenPoints,
  //     from: NaN, // the point is initially skipped
  //     delay(ctx) {
  //       if (ctx.type !== 'data' || ctx.xStarted) {
  //         return 0;
  //       }
  //       ctx.xStarted = true;
  //       return ctx.index * delayBetweenPoints;
  //     },
  //   },
  //   y: {
  //     type: 'number',
  //     easing: 'linear',
  //     duration: delayBetweenPoints,
  //     from: previousY,
  //     delay(ctx) {
  //       if (ctx.type !== 'data' || ctx.yStarted) {
  //         return 0;
  //       }
  //       ctx.yStarted = true;
  //       return ctx.index * delayBetweenPoints;
  //     },
  //   },
  // };
  const _unit = title.includes('Bức xạ') ? 'W/m2' : title.includes('Tốc độ') ? 'm/s' : 'kW';
  return (
    <div className='chart_container'>
      <Line
        data={chartData}
        options={{
          elements: {
            line: {
              borderWidth: [1, 1, 2, 1, 1, 2, 2],
              borderDash: [3, 3],
            },
            point: {
              radius: 1,
            },
          },
          // animation,
          interaction: {
            intersect: false,
          },
          plugins: {
            title: {
              display: true,
              text: title,
            },
            tooltip: {
              filter: function (item) {
                return !item.dataset.label.includes('GD-DB');
              },
              callbacks: {
                label: (item) => `${item.dataset.label}: ${item.formattedValue} ${_unit}`,
              },
            },
            legend: {
              display: legend,
              position: lgPosition || 'right',
              labels: {
                boxWidth: 12,
                usePointStyle: true,
                pointStyle: 'line',
                filter: function (item) {
                  // Logic to remove a particular legend item goes here
                  return !item.text.includes('GD-DB');
                },
              },
            },
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'minute',
                displayFormats: {
                  minute: 'dd/MM HH:mm',
                },
              },
              parsing: false,
              adapters: {
                date: {
                  locale: vi,
                },
              },
              ticks: {
                beginAtZero: false,
                stepSize: 30,
              },
            },
            y: {
              title: {
                display: true,
                text: _unit,
                font: {
                  weight: 'bold',
                },
              },
            },
          },
          // aspectRatio: aspectRatio || 1.5,
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 8,
              right: 0,
            },
          },
          animation: {
            duration: 0,
          },
        }}
      />
    </div>
  );
}
export default LineChart;
