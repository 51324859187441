import React, { useEffect, useState, useRef } from 'react';
import { format } from 'date-fns';
import formatISO from 'date-fns/formatISO';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js/auto';

import LineChart from '../Chart/LineChart';
import Navigation from '../Navigation/Navigation';

import blv_map from '../Image/blv_map_svg.svg';
import icon_weather from '../Image/icon-weather-station.png';
import icon_solar from '../Image/icon-solar-panel.png';
import icon_wind from '../Image/icon-WT-2.png';
import icon_bess from '../Image/icon-battery.png';
import icon_rmu from '../Image/icon-rmu2.png';
import icon_diesel from '../Image/icon-generator.png';

//register Chart from Chart.js
Chart.register(CategoryScale);

const General = (props) => {
  // useEffect(() => {
  //   //Fetch the user email and token from local storage
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   // console.log(user);
  //   //If the token/email does not exist, mark the user as logged out
  //   if (!user || !user.token) {
  //     setLoggedIn(false);
  //     return;
  //   } else {
  //     //verify user if needed
  //     setLoggedIn(true);
  //     setEmail(user.username);
  //   }
  // }, []);
  const fmNum = (o) => Number.parseFloat(o).toFixed(2);
  const toKW = (o) => parseFloat(o / 1e3).toPrecision(2);

  function RightPanelContainer() {
    const fcdata_default = [{ RMU2_P: 0, RMU3_P: 0, RMU4_P: 0, RMU8_P: 0, sc: '', time: null }];
    /* prettier-ignore */
    const optidata_default = [
      {
        ESS_Energy: 0,ESS_Power_Output: 0,
        Gen1: 0,Gen2: 0,Gen3: 0,Gen4: 0,Gen5: 0,
        PV1: 0,Wind1: 0,
        sc: 'sc01',time: null,
      },
    ];
    const recent_default = [{ RMU2_P: 0, RMU3_P: 0, RMU4_P: 0, RMU8_P: 0, time: null }];

    // const [fcdata, setFcdata] = useState(fcdata_default);
    const [fcdata_1hour, setFcdata_1hour] = useState(fcdata_default);

    // const [optiData_1day, setOptiData_1day] = useState(optidata_default);
    const [optiData_1hour, setOptiData_1hour] = useState(optidata_default);
    // const [recent, setRecent] = useState(recent_default);
    const [recent_1hour, setRecent_1hour] = useState(recent_default);

    useEffect(() => {
      async function getDataPreSet() {
        // const url_predict_1h = process.env.REACT_APP_API_DATAPRESET;
        const url_predict_1h = process.env.REACT_APP_API_PREDICT_1H;
        const url_predict_1d = process.env.REACT_APP_API_PREDICT_1D;
        const url_opti_1h = process.env.REACT_APP_API_OPTI_1H;
        const url_opti_1d = process.env.REACT_APP_API_OPTI_1D;
        const url_recent_1h = process.env.REACT_APP_API_RECENT_1H;
        const url_recent_1d = process.env.REACT_APP_API_RECENT_1D;
        try {
          Promise.all([
            fetch(url_predict_1d),
            fetch(url_predict_1h),
            fetch(url_opti_1d),
            fetch(url_opti_1h),
            fetch(url_recent_1d),
            fetch(url_recent_1h),
          ])
            // .then((response) => response.json())
            .then(function (responses) {
              return Promise.all(
                responses.map(function (response) {
                  // console.log(response);
                  return response.json();
                })
              );
            })
            .then((dt) => {
              // console.log(dt);
              // setFcdata([...dt.UncerData]);
              setFcdata_1hour([...dt.UncerData_1hour]);
              // setOptiData_1day([...dt.AllOutput_1day]);
              setOptiData_1hour([...dt.AllOutput_1hour]);
              // setRecent([...dt.recent]);
              setRecent_1hour([...dt.recent_1hour]);
              // localStorage.setItem('dataKey', JSON.stringify([...dt]));
              localStorage.setItem('dataKey', JSON.stringify(Object.assign({}, dt[0], dt[1], dt[2], dt[3], dt[4], dt[5])));
            });
        } catch (err) {
          console.error('Fetch dataPreSet: ' + err);
        }
      }

      const dataInStorage = localStorage.getItem('dataKey');
      if (!dataInStorage) {
        getDataPreSet();
        console.log('Data source: AP-01');
      } else {
        setFcdata_1hour([...dataPreSet.UncerData_1hour]);
        setOptiData_1hour([...dataPreSet.AllOutput_1hour]);
        setRecent_1hour([...dataPreSet.recent_1h]);
        console.log('Data source: LC-01');
      }
    }, []);

    const dataPreSet = JSON.parse(localStorage.getItem('dataKey'));

    function PlotCharts() {
      const fcData = [...fcdata_1hour];
      const recentData = [...recent_1hour];
      // console.log(fcData);
      // console.log(recentData);
      const recentTimeStamp = recentData.map((x) => formatISO(new Date(x.time)));
      const fcTimeStamp = fcData.map((x) => formatISO(new Date(x.time)));
      const timestamp = recentTimeStamp.concat(fcTimeStamp);
      //console.log(recentTimeStamp);console.log(fcTimeStamp);
      // console.log(timestamp);
      // console.log(fcData);
      const getFcData = (sc, type) => {
        let scStr = sc > 0 ? 'sc0' + sc : undefined; //vd: sc=1->scStr='sc01'
        let typeStr = type === 'PV' ? 'RMU8_P' : type === 'Wind' ? 'RMU4_P' : 'Load';
        return fcData
          .filter((x) => x.sc === scStr)
          .map((x) => (typeStr === 'Load' ? x['RMU2_P'] + x['RMU3_P'] : x[typeStr]))
          .map((x) => x.toFixed(2));
      };

      const convertToObj = (a, b) => {
        if (a.length !== b.length || a.length === 0 || b.length === 0) {
          return null;
        }
        let obj = [];

        // Using the foreach method
        for (let i = 0; a.length > i; i++) {
          obj.push({
            x: a[i],
            y: b[i],
          });
        }
        return obj;
      };

      let loadSC = [],
        windSC = [],
        pvSC = [];

      loadSC.push(convertToObj(fcTimeStamp, getFcData(0, 'Load')));
      windSC.push(convertToObj(fcTimeStamp, getFcData(0, 'Wind')));
      pvSC.push(convertToObj(fcTimeStamp, getFcData(0, 'PV')));

      const recentLoad = convertToObj(
        recentTimeStamp,
        recentData.map((x) => x.RMU2_P + x.RMU3_P)
      );

      const loadBridge = !loadSC[0] ? [] : [recentLoad.at(-1), loadSC[0].at(0)];
      // const loadBridge = [];
      const recentWind = convertToObj(
        recentTimeStamp,
        recentData.map((x) => x.RMU4_P + 11)
      );
      // const windBridge = [];
      const windBridge = !windSC[0] ? [] : [recentWind.at(-1), windSC[0].at(0)];
      const recentPV = convertToObj(
        recentTimeStamp,
        recentData.map((x) => x.RMU8_P)
      );
      // const pvBridge = [];
      const pvBridge = !pvSC[0] ? [] : [recentPV.at(-1), pvSC[0].at(0)];
      const chartData = {
        // labels: [...Array(pvSC01.length + 1).keys()].slice(1),
        labels: timestamp,
        datasets: [
          // {
          //   label: 'Dự báo CS Phụ tải SC04',
          //   data: loadSC[3],
          //   borderColor: 'rgb(54, 162, 235)', //blue
          //   backgroundColor: 'rgba(54, 162, 235, 0.3)', //blue
          //   borderDash: [3, 3],
          //   fill: '+1',
          // },
          // {
          //   label: 'Dự báo CS Phụ tải SC02',
          //   data: loadSC[1],
          //   borderColor: 'rgb(146, 195, 51)', //green
          //   backgroundColor: 'rgba(46, 195, 51, 0.4)', //green
          //   fill: '+1',
          // },
          {
            label: 'CS Dự báo SC01',
            data: loadSC[0],
            borderColor: 'rgb(255, 86, 29)', //tangerine
            backgroundColor: 'rgba(255, 86, 29,0.5)', //tangerine
            borderDash: [],
          },
          // {
          //   label: 'Dự báo CS Phụ tải SC03',
          //   data: loadSC[2],
          //   borderColor: 'rgb(146, 195, 51)', //green
          //   backgroundColor: 'rgba(46, 195, 51, 0.4)', //green
          //   fill: '-1',
          // },
          // {
          //   label: 'Dự báo CS Phụ tải SC05',
          //   data: loadSC[4],
          //   borderColor: 'rgb(54, 162, 235)', //blue
          //   backgroundColor: 'rgba(54, 162, 235, 0.3)', //blue
          //   borderDash: [3, 3],
          //   fill: '-1',
          // },
          {
            label: 'CS Phụ tải gần đây',
            data: recentLoad,
            // borderColor: 'rgb(201, 203, 207)', //gray
            // backgroundColor: 'rgba(201, 203, 207,0.5)', //gray
            borderColor: 'rgb(54, 162, 235)',
            backgroundColor: 'rgb(54, 162, 235,0.5)',
            borderDash: [],
          },
          {
            label: 'GD-DB',
            data: loadBridge,
            borderColor: 'rgb(54, 162, 235)', //gray
            backgroundColor: 'rgba(54, 162, 235,0.5)', //gray
            borderDash: [1, 2],
          },
        ],
      };
      const chartDataWT = {
        labels: timestamp,
        datasets: [
          // {
          //   label: 'Dự báo CS Đ.Gió - SC04',
          //   data: windSC[3],
          //   borderColor: 'rgb(54, 162, 235)', //blue
          //   backgroundColor: 'rgba(54, 162, 235, 0.3)', //blue
          //   borderDash: [3, 3],
          //   fill: '+1',
          // },
          // {
          //   label: 'Dự báo CS Đ.Gió - SC02',
          //   data: windSC[1],
          //   borderColor: 'rgb(146, 195, 51)', //green
          //   backgroundColor: 'rgba(46, 195, 51, 0.4)', //green
          //   fill: '+1',
          // },
          {
            label: 'CS Dự báo SC01',
            data: windSC[0],
            borderColor: 'rgb(255, 86, 29)', //tangerine
            backgroundColor: 'rgba(255, 86, 29,0.5)', //tangerine
            borderDash: [],
          },
          // {
          //   label: 'Dự báo CS Đ.Gió - SC03',
          //   data: windSC[2],
          //   borderColor: 'rgb(146, 195, 51)', //green
          //   backgroundColor: 'rgba(46, 195, 51, 0.4)', //green
          //   fill: '-1',
          // },
          // {
          //   label: 'Dự báo CS Đ.Gió - SC05',
          //   data: windSC[4],
          //   borderColor: 'rgb(54, 162, 235)', //blue
          //   backgroundColor: 'rgba(54, 162, 235, 0.3)', //blue
          //   borderDash: [3, 3],
          //   fill: '-1',
          // },
          {
            label: 'CS Đ.Gió gần đây',
            data: recentWind,
            borderColor: 'rgb(54, 162, 235)', //gray
            backgroundColor: 'rgba(54, 162, 235,0.5)', //gray
            borderDash: [],
          },
          {
            label: 'GD-DB',
            data: windBridge,
            borderColor: 'rgb(54, 162, 235)', //gray
            backgroundColor: 'rgba(54, 162, 235,0.5)', //gray
            borderDash: [1, 2],
          },
        ],
      };
      const chartDataPV = {
        labels: timestamp,
        datasets: [
          // {
          //   label: 'Dự báo CS ĐMT - SC04',
          //   data: pvSC[3],
          //   borderColor: 'rgb(54, 162, 235)', //blue
          //   backgroundColor: 'rgba(54, 162, 235, 0.3)', //blue
          //   borderDash: [3, 3],
          //   fill: '+1',
          // },
          // {
          //   label: 'Dự báo CS ĐMT - SC02',
          //   data: pvSC[1],
          //   borderColor: 'rgb(146, 195, 51)', //green
          //   backgroundColor: 'rgba(46, 195, 51, 0.4)', //green
          //   fill: '+1',
          // },
          {
            label: 'CS Dự báo SC01',
            data: pvSC[0],
            borderColor: 'rgb(255, 86, 29)', //tangerine
            backgroundColor: 'rgba(255, 86, 29,0.5)', //tangerine
            borderDash: [],
          },
          // {
          //   label: 'Dự báo CS ĐMT - SC03',
          //   data: pvSC[2],
          //   borderColor: 'rgb(146, 195, 51)', //green
          //   backgroundColor: 'rgba(46, 195, 51, 0.4)', //green
          //   fill: '-1',
          // },
          // {
          //   label: 'Dự báo CS ĐMT - SC05',
          //   data: pvSC[4],
          //   borderColor: 'rgb(54, 162, 235)', //blue
          //   backgroundColor: 'rgba(54, 162, 235, 0.3)', //blue
          //   borderDash: [3, 3],
          //   fill: '-1',
          // },
          {
            label: 'CS ĐMT gần đây',
            data: recentPV,
            borderColor: 'rgb(54, 162, 235)', //gray
            backgroundColor: 'rgba(54, 162, 235,0.5)', //gray
            borderDash: [],
          },
          {
            label: 'GD-DB',
            data: pvBridge,
            borderColor: 'rgb(54, 162, 235)', //gray
            backgroundColor: 'rgba(54, 162, 235,0.5)', //gray
            borderDash: [1, 2],
          },
        ],
      };

      return (
        <div className='chart_panel'>
          <div className='chart_item_90'>
            <LineChart chartData={chartData} aspectRatio={2} legend={true} lgPosition={'bottom'} title='Dự báo công suất Phụ Tải' />
            <p className='chart_title'>DỰ BÁO CÔNG SUẤT PHỤ TẢI</p>
          </div>
          <div className='chart_item_45'>
            <LineChart chartData={chartDataWT} aspectRatio={2} legend={true} lgPosition={'bottom'} title='Dự báo công suất Điện Gió' />
            <p className='chart_title'>DỰ BÁO CÔNG SUẤT ĐIỆN GIÓ (WIND)</p>
          </div>
          <div className='chart_item_45'>
            <LineChart chartData={chartDataPV} aspectRatio={2} legend={true} lgPosition={'bottom'} title='Dự báo công suất Điện Mặt Trời' />
            <p className='chart_title'>DỰ BÁO CÔNG SUẤT ĐIỆN MẶT TRỜI (PV)</p>
          </div>
        </div>
      );
    }

    function DieselGenTable() {
      const optiData = [...optiData_1hour];
      const showItem = (item) => {
        return parseFloat(item).toFixed(1);
      };
      const tabHeading = ['', 'Thời điểm', 'ESS EN', 'ESS PO', 'GEN 1', 'GEN 2', 'GEN 3', 'GEN 4', 'GEN 5', 'PV', 'WIND'];
      return (
        <table className='opti_24 fcast_24' style={{ width: 'calc(80% + 10px' }}>
          <thead>
            <tr>
              {tabHeading.map((x) => (
                <th key={x}>{x}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* //display only SC01:24 first rows */}
            {optiData.slice(0, 24).map((item, index) => (
              <tr key={`r- + ${index}`} className='trow'>
                <td>{index + 1}</td>
                <td>{format(new Date(item.time), 'hh:mm aa')}</td>
                <td>{showItem(item['ESS_Energy'])}</td>
                <td className={item['ESS_Power_Output'] > 0 ? 'discharged' : item['ESS_Power_Output'] < 0 ? 'charged' : 'other'}>
                  {showItem(item['ESS_Power_Output'])}
                </td>
                <td>{showItem(item.Gen1)}</td>
                <td>{showItem(item.Gen2)}</td>
                <td>{showItem(item.Gen3)}</td>
                <td>{showItem(item.Gen4)}</td>
                <td>{showItem(item.Gen5)}</td>
                <td>{showItem(item.PV1)}</td>
                <td>{showItem(item.Wind1)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
    return (
      <div className='panel_container vtop'>
        <h2 className='heading2'>DỰ BÁO CÔNG SUẤT PHỤ TẢI, ĐIỆN GIÓ, ĐIỆN MẶT TRỜI 01 GIỜ TỚI</h2>
        <PlotCharts />
        <h2 className='heading2'>LỊCH VẬN HÀNH TỐI ƯU CÁC NGUỒN ĐIỆN TRONG 01 GIỜ TỚI</h2>
        <DieselGenTable />
      </div>
    );
  }

  function LeftPanelContainer() {
    const [weatherData, setWeatherData] = useState([
      { id: 1, name: 'Air Temp.', value: 0, unit: 'oC' },
      { id: 2, name: 'Irradiance', value: 0, unit: ' W/m2' },
      { id: 3, name: 'Wind Speed', value: 0, unit: ' m/s' },
      { id: 4, name: 'Rainfall', value: 0, unit: ' mm' },
      { id: 5, name: 'Humidity', value: 0, unit: '%' },
    ]);
    const [sources, setSources] = useState([
      {
        id: 1,
        name: 'Solar Panel',
        abbr: 'SL',
        icon: icon_solar,
        figcap1: 'SOLAR',
        figcap2: 'PANEL',
        figcapalign: 'center',
        p: 0,
        q: 0,
      },
      {
        id: 2,
        name: 'Wind Turbine',
        abbr: 'W',
        icon: icon_wind,
        figcap1: 'WIND',
        figcap2: 'TURBINE',
        figcapalign: 'center',
        p: 0,
        q: 0,
      },
      {
        id: 3,
        name: 'BESS System',
        abbr: 'BESS',
        icon: icon_bess,
        figcap1: 'BESS',
        figcap2: 'SYSTEM',
        figcapalign: 'center',
        p: 0,
        q: 0,
      },
      {
        id: 4,
        name: 'Diesel Generator 4',
        abbr: 'DG4',
        icon: icon_diesel,
        figcap1: 'DIESEL',
        figcap2: 'GENERATOR 4,5',
        figcapalign: 'left',
        p: 0,
        q: 0,
      },
      {
        id: 5,
        name: 'Diesel Generator 5',
        abbr: 'DG5',
        icon: icon_diesel,
        figcap1: 'DIESEL',
        figcap2: 'GENERATOR 1,2,3',
        figcapalign: 'left',
        p: 0,
        q: 0,
      },
      {
        id: 6,
        name: 'R.M.U',
        abbr: 'RMU',
        icon: icon_rmu,
        figcap1: 'R.M.U',
        figcap2: 'SYSTEM',
        figcapalign: 'left',
        p: 0,
        q: 0,
      },
    ]);
    useEffect(() => {
      // console.log('This line OK');
      const getRealTimeData = async () => {
        const url = process.env.REACT_APP_API_RTDATE;
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            setWeatherData(
              weatherData.map((item) => {
                return item.id === 1
                  ? { ...item, value: data.PVMET500_Air_Temp.last }
                  : item.id === 2
                  ? { ...item, value: data.PVMET500_Irradiance_Global_Horizontal.last }
                  : item.id === 3
                  ? { ...item, value: data.WT_Wind_Speed.last }
                  : item.id === 4
                  ? { ...item, value: data.PVMET500_Rain_Fall.last }
                  : { ...item, value: data.PVMET500_Humidity.last };
              })
            );
            setSources(
              sources.map((source) => {
                switch (source.id) {
                  case 1:
                    return { ...source, p: data.COM100_Total_active_power.last, q: data.COM100_Total_reactive_power.last };
                  case 2:
                    return { ...source, p: data.WT_Active_Power_.last, q: data.WT_Reactive_Power.last };
                  case 3:
                    return { ...source, p: data.LC100_PCS_active_power.last, q: data.LC100_PCS_reactive_power.last };
                  case 4:
                    return { ...source, p: data.RMU6_P.last, q: data.RMU6_Q.last };
                  case 5:
                    return { ...source, p: data.RMU1_P.last, q: data.RMU1_Q.last };
                  case 6:
                    return { ...source, p: data.RMU2_P.last + data.RMU3_P.last, q: data.RMU7_P.last };
                  default:
                    return {};
                }
              })
            );
            // console.log('Just fetched realtime weather and sources data !');
          })
          .catch((err) => {
            // alert('Error:API Realtime');
            console.log('Fetch realtime data: ' + err);
          });
      };

      getRealTimeData();
      setInterval(() => {
        getRealTimeData();
      }, 5 * 60000);
    }, []);

    function DisplayWeatherInfo({ weatherData }) {
      const wth_items = ['Air Temp.', 'Irradiance', 'Wind Speed', 'Rainfall', 'Humidity'];
      return (
        <div className='weather_panel'>
          <img src={icon_weather} alt='icon weather' className='icon_weather' />
          <div className='wth_name'>
            {wth_items.map((item) => (
              <p key={item}>{item}:</p>
            ))}
          </div>
          <div className='wth_value'>
            {weatherData.map((item) => (
              <p key={item.id}>
                {item.value} {item.unit}
              </p>
            ))}
          </div>
        </div>
      );
    }
    function DisplayPowerSourcesOnMap({ sources }) {
      // console.log(sources);
      return (
        <div className='map_panel'>
          <img src={blv_map} alt='blv map' className='map' />
          {sources.map((item) => (
            <div key={item.id}>
              <figure className={`map_marker ${item.abbr}`}>
                <img src={item.icon} alt={`icon ${item.abbr}`} />
                <figcaption className={'left' === item.figcapalign ? 'txtLeft' : ''}>
                  {item.figcap1}
                  <p>{item.figcap2}</p>
                </figcaption>
              </figure>

              <div className={`green_line ${item.abbr}`}></div>
              <div className={`marker_panel ${item.abbr}`}>
                <div className='PG'>
                  {/* {item.id !== 6 && ( */}
                  <>
                    <p id={item.p}>{fmNum(item.p)}</p>
                    <p>{fmNum(item.q)}</p>
                  </>
                  {/* )} */}
                </div>
              </div>
            </div>
          ))}
          {/* <div>
            <figure className='map_marker RMU'>
              <img src={icon_rmu} alt='icon ' />
              <figcaption>
                R.M.U
                <p>SYSTEM</p>
              </figcaption>
            </figure>
          </div> */}
        </div>
      );
    }
    return (
      <div className='panel_container white'>
        <DisplayPowerSourcesOnMap sources={sources} />
        <DisplayWeatherInfo weatherData={weatherData} />
      </div>
    );
  }

  return (
    <>
      <Navigation loggedIn={props.loggedIn} setLoggedIn={props.setLoggedIn} pageId={1} />
      <section className={'home show'}></section>

      <section className='main_container'>
        <div className='left_panel'>
          <LeftPanelContainer />
        </div>
        <div className='right_panel'>
          <RightPanelContainer />
        </div>
      </section>
    </>
  );
};

export default General;
