import React from 'react';
import { useNavigate } from 'react-router-dom';
import { differenceInMinutes } from 'date-fns';

const Home = (props) => {
  console.log('Home: loaded');
  const { email, loggedIn } = props;
  // console.log('LoggedIn: ' + props.loggedIn);
  const navigate = useNavigate();

  const onButtonClick = () => {
    if (loggedIn) {
      // console.log('Logged in:' + props.loggedIn + '. Remove user - Logout');
      localStorage.clear();
      props.setLoggedIn(false);
    } else {
      // console.log('Logged in:' + props.loggedIn + '. Redirect to - Login');
      navigate('/login');
    }
  };

  return (
    <>
      <header className='header'>
        <nav className='nav homepage'>
          <span className='nav_logo_homepage'>
            <span className='nav_logo_homepage green'>RE:</span> BẠCH LONG VĨ
          </span>
          {!loggedIn && (
            <ul className='nav_items'>
              <li className='nav_item white '>
                <span className='nav_link'>
                  <span>Giới thiệu</span>
                </span>
              </li>
            </ul>
          )}
          <div className='greetingContainer'>
            {loggedIn ? (
              <div className='greeting'>
                Xin chào <strong>{email}</strong>
              </div>
            ) : (
              <div />
            )}
            <button className='button' onClick={onButtonClick}>
              {loggedIn ? 'Thoát' : 'Đăng nhập'}
            </button>
          </div>
        </nav>
      </header>
      <section className={'home'}></section>
    </>
  );
};

export default Home;
